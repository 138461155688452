import React, { useEffect, useState } from "react";
import {
  axiosInstance,
  getBaseEndpoint,
  useAxios,
} from "@bjsdistribution/dms-component-library";
import { setLoginUserDetails } from "./redux/features/appMangerUtilitySlice";
import { getDbSyncBaseEndpoint, getUrlParams } from "./common/utils";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios, { AxiosError } from "axios";
import styled from "styled-components";
import { Spin } from "antd";
const Login = ({ setIsLogin }: { setIsLogin: any }) => {
  const hideLoader = () => {
    const loader = document.getElementById("global-loader");
    if (loader) {
      loader.style.display = "none";
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const errorApi = queryParams.get("error");
  const [errorMess, setErrorMess] = useState<any>(errorApi);
  const ID_TOKEN = "id_token";
  const hash = window.location.hash.substring(1);
  const params = getUrlParams(hash);
  const idToken = params.get(ID_TOKEN);

  // DBSYNC API's
  const fetchDbSyncData = async (url: string) => {
    const baseUrl = getDbSyncBaseEndpoint();
    try {
      const response = await axios.get(url, {
        baseURL: baseUrl,
      });
      return response;
    } catch (err) {
      console.log(err);
    }
  };
  const callDbSyncApis = async () => {
    fetchDbSyncData("pull_gsit_depot_data/");
    fetchDbSyncData("pull_gsit_customers_data/");
    const response = await fetchDbSyncData("pull_gsit_subcontractor_data/");
    if (response?.status === 200) {
      fetchDbSyncData("pull_gsit_zones_data/");
    }
  };

  const BeginLogin = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("begin_login", {
        withCredentials: true,
        baseURL,
      });
      if (response.data?.status === "success") {
        window.location.href = response.data.data;
      } else {
        navigate("/login");
      }
    } catch (error) {
      const errorMessage =
        error instanceof AxiosError
          ? error.response?.status
          : "Login initiation error.";
      setErrorMess(errorMessage);
    } finally {
      setIsLoading(false);
      hideLoader();
    }
  };

  const baseURL = getBaseEndpoint();
  const SsoLogin = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get("sso_auth", {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        withCredentials: true,
        baseURL,
      });
      if (response.data?.username) {
        localStorage.setItem("username", response?.data.username);
        setLoginUserDetails(response?.data?.username);
        // call dbsync api's
        callDbSyncApis();
        setIsLogin(true);
        navigate("/routes");
      }
    } catch (error: any) {
      const errorMessage =
        error instanceof AxiosError
          ? error.response?.status
          : "Login initiation error.";

      setErrorMess(errorMessage);
    } finally {
      setIsLoading(false);
      hideLoader();
    }
  };

  useEffect(() => {
    SsoLogin();
  }, []);

  const loginWithSso = () => {
    BeginLogin();
  };

  const getErrorMessage = (error: any) => {
    switch (error) {
      case 401:
        return "Session expired, please log in again.";
      case 403:
        return "Access denied unable the login.";
      case 422:
        return "Unprocessable content Authentication failed .";
      default:
        return "An unknown error occurred. Please try again later.";
    }
  };

  return (
    <LoginContainer>
      <LoginBox>
        <h2>Welcome to DMS</h2>
        <button disabled={isLoading} onClick={() => loginWithSso()}>
          {" "}
          {isLoading ? <Spin /> : "Login with SSO"}
        </button>
        {errorMess && <ErrorMessage>{getErrorMessage(errorMess)}</ErrorMessage>}
      </LoginBox>
    </LoginContainer>
  );
};

export default Login;
// Styled components
const LoginContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(235 235 235);
  background-size: cover;
  /* filter: blur(5px); */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  width: 500px;
  height: 393px;
  background-color: white;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 1;
  position: relative;

  h2 {
    margin-bottom: 30px;
  }

  p {
    position: absolute;
    bottom: 25px;
    right: 0;
    text-transform: uppercase;
    left: 0;
  }
  button {
    border: none;
    background: rgb(254, 186, 48);
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  button:disabled {
    cursor: default;
    opacity: 0.8;
  }
`;

const ErrorMessage = styled.p`
  color: #df3838;
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
`;
